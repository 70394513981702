@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.drag-element {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.drag-element.is-dragging {
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: lightgray;
  z-index: 1;
}

.controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  width: auto;
  height: auto;
  border-radius: 8px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 3;
}

.shadow {
  position: fixed;
  right: 0;
  top: 0;
  width: 40%;
  height: 100%;
  opacity: 0.65;
  z-index: 1;
  pointer-events: none;

  background: rgb(0,0,0);
  background: -moz-linear-gradient(-90deg, rgba(0,0,0,0.2007177871148459) 0%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(-90deg, rgba(0,0,0,0.2007177871148459) 0%, rgba(0,0,0,0) 100%);
  background: linear-gradient(-90deg, rgba(0,0,0,0.2007177871148459) 0%, rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.company_position_info {
  display: block;
  padding: 10px 40px;
  background-color: #182d8e;
  border: 2px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 28px;
}

.ausbildung-studium {
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  background: #6D9442;
  padding: 13px 23px;

  margin-top: 10px;
}

.company_description {
  padding-left: 10px;
  border-left: 3px solid gray;
  margin-bottom: 15px;
}

.ant-modal.left > .ant-modal-content {
  left: -200px;
}
.ant-modal.left .ant-modal-close {
  top: 5px;
  right: 5px;
}

.ant-modal.right > .ant-modal-content {
  left: 500px;
}
.ant-modal.right .ant-modal-close {
  display: none;
}

.filter-content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 10px;
  width: auto;
  height: auto;
  border-radius: 8px;
  background-color: #e8e8e8;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 4px;
  z-index: 3;
}

.filter-companies {
  position: absolute;
  bottom: 10px;
  left: 400px;
  padding: 10px;
  width: auto;
  height: auto;
  border-radius: 8px;
  background-color: #e8e8e8;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 4px;
  z-index: 2;
}


.ant-tabs {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  background-color: #124084 !important;
  /*background-color: #9fbac2;*/
  padding-right: 15px;
  margin-bottom: 20px;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-nav-wrap {}

.ant-tabs .ant-tabs-tab:hover {
  color: #124084;
}


.ant-tabs-tab.ant-tabs-tab-active {
  margin:0;
  background-color: white !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}

.ant-tabs-tab {
  color: white !important;
  font-size: 18px !important;
  background-color: #124084 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: 0 !important;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: rgba(255, 0, 0, 0);
}

:where(.ant-tabs-tab).ant-modal .ant-modal-close {
  color: rgba(255, 255, 255, 0.45);
}

.ant-collapse-expand-icon {
  color: white;
}